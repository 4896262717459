import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SuperDashboardState } from './super-dashboard.state';
export const SUPER_DASHBOARD_STATE_NAME = 'super_dashboard';
export const selectSuperDashboardState =
  createFeatureSelector<SuperDashboardState>(SUPER_DASHBOARD_STATE_NAME);

const selectVisitorCount = createSelector(
  selectSuperDashboardState,
  state => state.visitorCount
);

const selectQueryCount = createSelector(
  selectSuperDashboardState,
  state => state.queryCount
);

const selectAllSources = createSelector(
  selectSuperDashboardState,
  state => state.querySources
);

const selectQueryBySources = createSelector(
  selectSuperDashboardState,
  state => state.queryBySources
);

const selectQueryByStatus = createSelector(
  selectSuperDashboardState,
  state => state.qeryByStatus
);

export default {
  selectVisitorCount,
  selectQueryCount,
  selectAllSources,
  selectQueryBySources,
  selectQueryByStatus,
};
