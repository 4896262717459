import { SuperDashboardModel, DashboardPageModel } from '@models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const superDashboardActions = createActionGroup({
  source: 'Super_Dashboard page',
  events: {
    'Visitor Count': props<{ filter: Record<string, unknown> }>(),
    'Query Count': props<{
      filter: Record<string, unknown>;
    }>(),
    'Visitor Count Success': props<{
      data: DashboardPageModel.VisitorCountResponse;
    }>(),
    'Query Count Success': props<{
      data: DashboardPageModel.QueryCountResponse;
    }>(),
    'All Queries by Source': props<{ filter: Record<string, unknown> }>(),
    'All Queries by Source Success': props<{
      data: SuperDashboardModel.QueryBySourceResponse[];
    }>(),
    'All Query Status': props<{ filter: Record<string, unknown> }>(),
    'All Query Status Success': props<{
      data: SuperDashboardModel.QueryByStatusResponse[];
    }>(),
    'Query Sources': emptyProps(),
    'Query Sources Success': props<{
      data: SuperDashboardModel.AllQuerySourcesResponse[];
    }>(),
  },
});
