import { Injectable } from '@angular/core';
import { BaseService } from '@utils';
import { DashboardPageModel } from '@models';
import { Duration, Organization, SuperDashboardModel } from '@models';
@Injectable()
export class SuperDashboardService extends BaseService {
  getVisitorCount(filter: Record<string, unknown>) {
    return this.httpClient.get<DashboardPageModel.VisitorCountResponse>(
      'visitors/visitorCountWithinDate',
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }
  getQueriesCount(filter: Record<string, unknown>) {
    return this.httpClient.get<DashboardPageModel.QueryCountResponse>(
      `/Queries/bySource`,
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }

  getQueriesByDuration(
    duration: Duration,
    organizationId?: Organization['id']
  ) {
    return this.httpClient.get<any[]>(`Queries/countQueriesByDate`, {
      params: this.convertToHttpParams({ duration, organizationId }),
    });
  }
  getLeadsByDuration(duration?: Duration, organizationId?: Organization['id']) {
    return this.httpClient.get<any[]>(`Leads/countLeadsByDate`, {
      params: this.convertToHttpParams({ duration, organizationId }),
    });
  }
  getAllQuerySource() {
    return this.httpClient.get<SuperDashboardModel.AllQuerySourcesResponse[]>(
      `settings/allSources`
    );
  }
  getQueryBySource(filter: Record<string, unknown>) {
    return this.httpClient.get<SuperDashboardModel.QueryBySourceResponse[]>(
      `Organizations/queriesBySource`,
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }
  getQueryByStatus(filter: Record<string, unknown>) {
    return this.httpClient.get<SuperDashboardModel.QueryByStatusResponse[]>(
      `Organizations/queryStatus`,
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }
}
